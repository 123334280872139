<template>
  <notification-group group="app">
    <div class="fixed top-5 z-[100] w-full items-center justify-center sm:max-w-md md:right-0 md:top-auto">
      <notification v-slot="{ notifications, close }">
        <template v-for="notification in notifications" :key="notification.id">
          <NotificationFlash :notification="notification" :close="close" />
        </template>
      </notification>
    </div>
  </notification-group>
</template>

<script setup>
import { onMounted, watch } from 'vue';
import NotificationFlash from './NotificationFlash.vue';
import { flashMessage } from '@/composeables/helpers';

const props = defineProps({
  flashMessages: {
    type: Array
  }
});
onMounted(() => {
  if (props.flashMessages.length > 0) {
    props.flashMessages.forEach(msg => {
      if (msg[1] !== '') {
        flashMessage({ type: msg[0], message: msg[1] });
      }
    });
  }
});
watch(
  () => props.flashMessages,
  () => {
    if (props.flashMessages.length > 0) {
      props.flashMessages.forEach(msg => {
        if (msg[1] !== '') {
          flashMessage({ type: msg[0], message: msg[1] });
        }
      });
    }
  }
);
</script>
